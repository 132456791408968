import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_headline = _resolveComponent("page-headline")!
  const _component_home_our_stories = _resolveComponent("home-our-stories")!
  const _component_launch_your_product = _resolveComponent("launch-your-product")!

  return (_ctx.page)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_page_headline, { content: _ctx.page }, null, 8, ["content"]),
        _createVNode(_component_home_our_stories, {
          "show-more-btn": false,
          "items-to-show": this.pageLimit
        }, null, 8, ["items-to-show"]),
        _createVNode(_component_launch_your_product)
      ]))
    : _createCommentVNode("", true)
}