
import { contentsService, StaticContent } from "@/services/contents.service";
import { defineComponent } from "vue";
import {
  GenericContent,
  Paginator,
  paginatorService,
} from "@/services/paginator.service";
import StoryTile from "@/modules/success-stories/StoryTile.vue";
import LaunchYourProduct from "@/components/LaunchYourProduct.vue";
import PageHeadline from "@/common/PageHeadline.vue";
import { setPageDescription, setPageTitle } from "@/common/meta.utils";
import HomeOurStories from "@/modules/success-stories/HomeOurStories.vue";

export default defineComponent({
  name: "SuccessStoriesPaginator",
  components: { HomeOurStories, PageHeadline, LaunchYourProduct },
  props: {},
  data() {
    return {
      page: undefined as StaticContent | undefined,
      paginator: undefined as Paginator | undefined,
      flipList: [] as GenericContent[][],
      pageNo: 1,
      pageLimit: 9,
    };
  },
  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      contentsService.findBySlug("page-success-stories").then((page) => {
        this.page = page;
        setPageTitle(page?.title);
        setPageDescription(page?.lead);
      });
      paginatorService
        .paginator("success-stories-collection", {
          page: this.pageNo,
          limit: this.pageLimit,
        })
        .then((paginator) => {
          this.paginator = paginator;
          this.flipList = this.flip(3, [...paginator.list]);
        });
    },
    showMore() {
      this.pageNo += 1;
      paginatorService
        .paginator("success-stories-collection", {
          page: this.pageNo,
          limit: this.pageLimit,
        })
        .then((paginator) => {
          this.paginator = paginator;
          this.flipList.push(...this.flip(3, [...paginator.list]));
        });
    },
    flip(by = 3, list: GenericContent[]) {
      const fipped = [];
      const s = Math.ceil(list.length / 3);
      for (let i = 0; i < s; i++) {
        fipped.push(list.splice(0, 3));
      }
      return fipped;
    },
    goto(item: GenericContent) {
      this.$router.push("/success-stories/" + item.slug);
    },
  },
});
