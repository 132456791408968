
import { defineComponent } from "vue";
import { GenericContent, paginatorService } from "@/services/paginator.service";
import PageHeadline from "@/common/PageHeadline.vue";
import HomeOurStories from "@/modules/success-stories/HomeOurStories.vue";
import { setPageDescription, setPageTitle } from "@/common/meta.utils";
import LaunchYourProduct from "@/components/LaunchYourProduct.vue";
import { contentsService, StaticContent } from "@/services/contents.service";

export default defineComponent({
  name: "SuccessStory",
  components: { LaunchYourProduct, PageHeadline, HomeOurStories },
  props: {
    slug: String as () => string,
  },
  data() {
    return {
      isItem: false,
      item: undefined as GenericContent | undefined,
      page: undefined as StaticContent | undefined,
    };
  },
  mounted() {
    if (this.slug) {
      this.loadContent(this.$route.params.slug as string);
    }
  },

  methods: {
    loadContent(slug: string) {
      contentsService.findBySlug("page-success-stories").then((page) => {
        this.page = page;
      });
      paginatorService
        .findBySlug("success-stories-collection", slug)
        .then((item) => {
          if (!item) {
            this.$router.push("/success-stories/");
          } else {
            this.item = item;
            setPageTitle(item?.title);
            setPageDescription(item?.lead);
          }
        });
    },
  },
  watch: {
    slug(slug) {
      if (slug) {
        this.loadContent(slug);
      } else {
        this.item = undefined;
      }
    },
  },
});
