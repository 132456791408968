
import { defineComponent } from "vue";
import SuccessStoriesPaginator from "@/modules/success-stories/SuccessStoriesPaginator.vue";
import SuccessStory from "@/modules/success-stories/SuccessStory.vue";

export default defineComponent({
  name: "SuccessStories",
  components: { SuccessStory, SuccessStoriesPaginator },
  data() {
    return {
      slug: "",
    };
  },
  mounted() {
    if (this.$route.params.slug) {
      this.slug = this.$route.params.slug as string;
    }
  },

  methods: {},
  watch: {
    $route(route) {
      if (route.params.slug) {
        this.slug = route.params.slug as string;
      } else {
        this.slug = "";
      }
    },
  },
});
