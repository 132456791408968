import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12bcd046"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_success_story = _resolveComponent("success-story")!
  const _component_success_stories_paginator = _resolveComponent("success-stories-paginator")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.slug)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_success_story, { slug: _ctx.slug }, null, 8, ["slug"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_success_stories_paginator)
        ]))
  ]))
}